import React from 'react'
import { graphql, Link } from 'gatsby'
import Container from '../components/container'
// import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hub/hero/hero'
import CardSlider from '../components/hub/cardSlider/cardSlider'
import BlockContent from '../components/block-content/v2'

import * as styles from '../components/globals-v2.module.css'
import Icon from '../components/icons'
import * as linkStyle from '../styles/components/links.module.css'
import * as start from '../pages/content-hub/start.module.css'
import Card from '../components/hub/card/card'
import { Image } from '../components/Image'
import { isExternalUrl } from '../lib/helpers'

export const query = graphql`
  query VideoTemplateQuery($id: String!, $seriesTitle: String!) {
      video: sanityVideo(id: { eq: $id }) {
          _id
          _type
          tags {
              title
              slug {
                  current
              }
          }
          title
          footnote
          slug {
              current
          }
          series {
              title
          }
          youtubePlayer
          relatedContent {
              ... on SanityAudio {
                  _type
                  title
                  slug {
                      current
                  }
                  series {
                      title
                      slug {
                          current
                      }
                  }
                  tags {
                      title
                      slug {
                          current
                      }
                  }
                  featureImage {
                      asset {
                          _id
                          url
                      }
                  }
                  appleUrl
                  spotifyUrl
                  youTubeUrl
              }
              ... on SanityVideo {
                  _type
                  title
                  slug {
                      current
                  }
                  series {
                      title
                      slug {
                          current
                      }
                  }
                  tags {
                      title
                      slug {
                          current
                      }
                  }
                  featureImage {
                      asset {
                          _id
                          url
                      }
                  }
                  appleUrl
                  spotifyUrl
                  youTubeUrl
              }
              ... on SanityWritten {
                  _id
                  _type
                  publishedAt
                  tags {
                      title
                      slug {
                          current
                      }
                  }
                  title
                  slug {
                      current
                  }
                  featureImage {
                      asset {
                          url
                          _id
                      }
                  }
              }
          }
          podcastsSection {
              ... on SanityAudio {
                  _type
                  title
                  slug {
                      current
                  }
                  series {
                      title
                      slug {
                          current
                      }
                  }
                  featureImage {
                      asset {
                          _id
                          url
                      }
                  }
                  appleUrl
                  spotifyUrl
                  youTubeUrl
              }
              ... on SanityVideo {
                  _type
                  title
                  slug {
                      current
                  }
                  series {
                      title
                      slug {
                          current
                      }
                  }
                  featureImage {
                      asset {
                          _id
                          url
                      }
                  }
                  appleUrl
                  spotifyUrl
                  youTubeUrl
              }
          }
          videoId
          youtubePlayer
          featureImage {
              asset {
                  _id
                  url
              }
          }
          _rawTranscript
          metaTitle
          metaDescription
          metaKeywords
          metaImageFB {
              asset {
                  _id
                  url
              }
          }
          metaImageTW {
              asset {
                  _id
                  url
              }
          }
          appleUrl
          spotifyUrl
          youTubeUrl
      }

      settings: sanityContentHubSettings {
          podcastsSection {
              _id
              _type
              title
              seriesTitle
              slug {
                  current
              }
              featureImage {
                  asset {
                      _id
                      url
                  }
              }
              appleUrl
              spotifyUrl
              youTubeUrl
          }
      }

      episodes: allSanityVideo(limit: 5, sort: {order: DESC, fields: publishedAt}, filter: {id: { ne: $id }, series: { title:{ eq: $seriesTitle }}}) {
          nodes {
              _type
              tags {
                  title
                  slug {
                      current
                  }
              }
              title
              slug {
                  current
              }
              series {
                  title
              }
              featureImage {
                  asset {
                      _id
                      url
                  }
                  hotspot {
                      _key
                      _type
                      x
                      y
                      height
                      width
                  }
              }
          }
      }
      
      podcasts: allSanityAudio(limit: 3, sort: {order: DESC, fields: publishedAt}, filter: {id: { ne: $id }}) {
          nodes {
              _id
              _type
              publishedAt
              tags {
                  title
                  slug {
                      current
                  }
              }
              title
              slug {
                  current
              }
              series {
                  title
                  slug {
                      current
                  }
              }
              featureImage {
                  asset {
                      _id
                      url
                  }
              }
              appleUrl
              spotifyUrl
              youTubeUrl
              metaViews
              timeReads
          }
      }

      social: allSanitySocial (sort: {order: DESC, fields: publishedAt}) {
          nodes {
              url
              title
              platform
              firstIndicator,
              lastIndicator,
              description,
              featureImage: image {
                  asset {
                      _id
                      url
                  }
              }
          }
      }
  }
`

const VideoTemplate = props => {
  const { data } = props
  const video = data && data.video
  const episodes = (data.video.relatedContent !== null && data.video.relatedContent.length > 0) ? data.video.relatedContent : data.episodes?.nodes
  const podcasts = (data.video.podcastsSection !== null && data.video.podcastsSection.length > 0) ? data.video.podcastsSection : data.settings?.podcastsSection
  const social = data.social.nodes

  // useEffect(() => {
  //   const updateViews = async () => {
  //     try {
  //       // eslint-disable-next-line no-undef
  //       await fetch('/api/updateViews/', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({ postId: video._id })
  //       })
  //     } catch (error) {
  //       console.error('Error updating views:', error)
  //     }
  //   }
  //
  //   updateViews()
  // }, [video._id])

  return (
    <Layout hideContactBar hideAnnouncements logoOverrideUrl='/content-hub/' palette='red'>

      <SEO
        title={video.metaTitle || video.title} description={video.metaDescription} keywords={video.metaKeywords}
        imageFB={video.metaImageFB} imageTW={video.metaImageTW}
      />
      <Container class={styles.container} style={{ paddingBottom: 0 }}>
        <Hero type='video' record={video} />
        <section className={styles.singlePageContainer}>
          <div className={styles.singlePageContent}>
            <div className={styles.singlePageContentTitle}>
              <Image imgObj={video.featureImage} props={{ width: 150, height: 150, alt: video.title }} />
              <div className={styles.singlePageContentTitleText}>
                <h1>{video.title}</h1>
                {(video.spotifyUrl || video.appleUrl || video.youTubeUrl) && (
                  <div className={`${styles.listenOn} ${styles.icons}`}><span>Listen on</span>
                    {video.spotifyUrl && (
                      <a target={isExternalUrl(video.spotifyUrl) ? '_blank' : '_self'} className={styles.spotify} href={video.spotifyUrl} rel='noreferrer'><Icon symbol='spotify' /></a>)}
                    {video.appleUrl && (<a target={isExternalUrl(video.appleUrl) ? '_blank' : '_self'} className={styles.apple} href={video.appleUrl} rel='noreferrer'><Icon symbol='apple' /></a>)}
                    {video.youTubeUrl && (
                      <a target={isExternalUrl(video.title) ? '_blank' : '_self'} className={styles.youtube} href={video.youTubeUrl} rel='noreferrer'><Icon symbol='youtube' /></a>)}
                  </div>
                )}
              </div>
            </div>
            {video._rawTranscript && (
              <div className={styles.singlePageContentTranscription}>
                <div className={styles.singlePageContentTranscriptionTitle}>Video Transcript</div>
                <BlockContent
                  className={styles.singlePageContentTranscriptionContent} blocks={video._rawTranscript}
                  timecode
                />
              </div>
            )}
          </div>
          <div className={styles.singlePageRelated}>
            <div className={styles.singlePageRelatedTitle}>Related videos</div>
            {episodes?.length > 0 ? (
              episodes.map((episode, index) => (
                <div key={index} className={styles.singlePageRelatedCardWrapper}>
                  <Link to={`/content-hub/${episode._type}/${episode.slug?.current}/`}>
                    <Image imgObj={episode.featureImage} props={{ width: 436, height: 228, alt: episode.title }} style={{ maxHeight: 128 + 'px' }} />
                  </Link>
                  <div className={styles.singlePageRelatedCard}>
                    <div className={styles.singlePageRelatedCardTags}>
                      {episode.tags !== null && (
                        episode.tags.map((tag, index) => (
                          <Link to={`/content-hub/category/${tag.slug?.current}/`} key={index} className={styles.singlePageRelatedCardTag}>{tag.title}</Link>
                        ))
                      )}
                    </div>
                    <Link to={`/content-hub/${episode._type}/${episode.slug?.current}/`} className={styles.singlePageRelatedCardTitle}>{episode.title}</Link>
                  </div>
                </div>
              ))) : (<div>No related videos</div>)}
            {/*<div className={styles.singlePageRelatedCardMore}>*/}
            {/*  <Link to='#' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>See more*/}
            {/*    <span>*/}
            {/*      <div className={linkStyle.iconCircle} />*/}
            {/*      <div className={`${linkStyle.icon} ${styles.icon}`}>*/}
            {/*        <Icon symbol='newsArrowIcon' color='currentColor' />*/}
            {/*      </div>*/}
            {/*    </span>*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
        </section>

        <section className={styles.podcastsContainer}>
          {podcasts !== null && (
            <div className={`${start.oneColumn} ${start.separator} ${start.podcastsWrapper} ${start.red}`}>
              <div className={start.heading}>Podcasts</div>
              <div className={`${start.sectionWrapper} ${start.podcastsWrapper}`}>
                {podcasts.map((podcast, index) => (
                  <Card key={index} options={{ red: true }} type='simpleMedia' section='podcasts' data={podcast} />
                ))}
              </div>
            </div>
          )}
        </section>
      </Container>

      <Container class={`${styles.container} ${styles.sliderOverflow}`}>
        <section className={styles.socialContainer}>
          <div className={`${start.oneColumn} ${start.separator} ${start.red}`}>
            <CardSlider title='Social' type='social' data={social} settings={{ slidesToShow: 4 }} alternate />
          </div>
        </section>
      </Container>

      <Container class={`${styles.defaultPaddings} ${styles.socialButtonsWrapper}`}>
        <Link to='https://www.youtube.com/@redpoint_ventures' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>Youtube
          <span>
            <div className={linkStyle.iconCircle} />
            <div className={`${linkStyle.icon} ${styles.icon}`}>
              <Icon symbol='newsArrowIcon' color='currentColor' />
            </div>
          </span>
        </Link>
        <Link to='https://www.instagram.com/redpoint/?hl=en' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>Insta
          <span>
            <div className={linkStyle.iconCircle} />
            <div className={`${linkStyle.icon} ${styles.icon}`}>
              <Icon symbol='newsArrowIcon' color='currentColor' />
            </div>
          </span>
        </Link>
        <Link to='https://www.tiktok.com/@redpoint?lang=en' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>TikTok
          <span>
            <div className={linkStyle.iconCircle} />
            <div className={`${linkStyle.icon} ${styles.icon}`}>
              <Icon symbol='newsArrowIcon' color='currentColor' />
            </div>
          </span>
        </Link>
        <Link to='https://www.linkedin.com/company/redpointventures' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>LinkedIn
          <span>
            <div className={linkStyle.iconCircle} />
            <div className={`${linkStyle.icon} ${styles.icon}`}>
              <Icon symbol='newsArrowIcon' color='currentColor' />
            </div>
          </span>
        </Link>
        <Link to='https://x.com/redpoint' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>X
          <span>
            <div className={linkStyle.iconCircle} />
            <div className={`${linkStyle.icon} ${styles.icon}`}>
              <Icon symbol='newsArrowIcon' color='currentColor' />
            </div>
          </span>
        </Link>
        <Link to='https://open.spotify.com/show/5WqBqDb4br3LlyVrdqOYYb' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>Spotify
          <span>
            <div className={linkStyle.iconCircle} />
            <div className={`${linkStyle.icon} ${styles.icon}`}>
              <Icon symbol='newsArrowIcon' color='currentColor' />
            </div>
          </span>
        </Link>
      </Container>
    </Layout>
  )
}

export default VideoTemplate
